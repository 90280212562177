import { easePolyInOut } from "d3-ease"
import { graphql, Link, useStaticQuery } from "gatsby"
import { changeLocale } from "gatsby-plugin-intl"
import React, { useEffect, useRef } from "react"
import { animated, useTransition } from "react-spring"
import { GraphQLListResponse } from "../types"
import { IMeta } from "../types/models/meta"
import * as R from "ramda"
import "./menu.scss"
import { useEffectOnceWhen } from "use-effect-once-when"
import { useRope } from "./useRope"

export const Menu = ({
  open,
  onClose,
  logoRef,
}: {
  open: boolean
  onClose: () => void
  logoRef: any
}) => {
  const navRef: any = useRef(null)
  const canvasRef: any = useRef(null)
  // const [drawRope, rope, setOpen, clearCanvas] = useRope({
  //   canvasRef,
  //   navRef,
  //   open,
  //   logoRef,
  // })

  const data = useStaticQuery<GraphQLListResponse<IMeta>>(graphql`
    {
      allStrapiMeta {
        edges {
          node {
            contact {
              Bandcamp
              Spotify
              Twitter
              Weibo
              YouTube
              Instagram
            }
          }
        }
      }
    }
  `)

  // useEffectOnceWhen(
  //   !R.isNil((logoRef || {}).current) &&
  //     !R.isNil((canvasRef || {}).current) &&
  //     !R.isNil((navRef || {}).current),
  //   () => {
  //     if (
  //       !R.isNil((logoRef || {}).current) &&
  //       !R.isNil((canvasRef || {}).current) &&
  //       !R.isNil((navRef || {}).current)
  //     ) {
  //       drawRope({
  //         navRef,
  //         canvasRef,
  //       })
  //     }
  //   }
  // )
  // useEffect(() => {
  //   setOpen(open)
  //   if (open && rope) {
  //     if (
  //       !R.isNil((logoRef || {}).current) &&
  //       !R.isNil((canvasRef || {}).current) &&
  //       !R.isNil((navRef || {}).current)
  //     ) {
  //       drawRope()
  //     }
  //   }
  //   return () => {
  //     clearCanvas()
  //   }
  // }, [open])

  const contactInfo = R.pathOr<any>({}, [
    "allStrapiMeta",
    "edges",
    0,
    "node",
    "contact",
  ])(data)
  const intl = {
    menus: {
      en: {
        "nav.releases": "Releases",
        "nav.artists": "Artists",
        "nav.radio": "Radio",
        "nav.education": "Education",
        "nav.about": "About",
      },
      zh: {
        "nav.releases": "新作一览",
        "nav.artists": "艺术家们",
        "nav.radio": "电台放送",
        "nav.education": "啊！教育",
        "nav.about": "关于我们",
      },
      ja: {
        "nav.releases": "リリース",
        "nav.artists": "アーティスト",
        "nav.radio": "ラジオ",
        "nav.education": "レッスン",
        "nav.about": "HEMについて",
      },
    },
    formatMessage: ({ id }: any) => {
      const lang = localStorage.getItem("hem_lang")
      //@ts-ignore
      return intl.menus[lang || "en"][id]
    },
    locale: localStorage.getItem("hem_lang") || "en",
  }

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 1,
      easing: easePolyInOut,
    },
    onRest: () => {},
  })

  const onLeave = (callback?: () => void) => {
    onClose()
    if (callback) {
      setTimeout(() => callback(), 150)
    }
  }

  const isActiveLink = (name: string) => {
    if (name === "/") {
      return location.href.indexOf(`${intl.locale}/`) ===
        location.href.length - 3
        ? "active"
        : ""
    } else {
      return location.href.indexOf(name) > -1 ? "active" : ""
    }
  }

  return transitions(
    (styles, item) =>
      item && (
        <animated.div style={styles} className="menu">
          <div className="grid grid-cols-1 md:grid-cols-3  w-full h-full">
            <div className="pl-6 pb-6 menu-left flex-col justify-end hidden md:flex PPG-Bold hao">
              <strong>Hao Electronic Music</strong>
            </div>
            <div className="menu-center hidden md:flex"></div>
            <div className="menu-left flex flex-col">
              <div className="menu-locales pl-6 flex justify-start align-center PPG-Bold">
                <button
                  onClick={() => {
                    changeLocale("en")
                    localStorage.setItem("hem_lang", "en")
                  }}
                  className={`locale ${intl.locale === "en" ? "active" : ""}`}
                >
                  EN
                </button>
                <span className="ABC-Mono">/</span>
                <button
                  onClick={() => {
                    changeLocale("zh")
                    localStorage.setItem("hem_lang", "zh")
                  }}
                  className={`locale ${intl.locale === "zh" ? "active" : ""}`}
                >
                  ZH
                </button>
                <span className="ABC-Mono">/</span>
                <button
                  onClick={() => {
                    changeLocale("ja")
                    localStorage.setItem("hem_lang", "ja")
                  }}
                  className={`locale ${intl.locale === "ja" ? "active" : ""}`}
                >
                  JA
                </button>
              </div>
              <nav
                ref={navRef}
                className="menu-nav flex-1 flex flex-col justify-center PPG-Bold"
              >
                <ul>
                  <li
                    className={isActiveLink("/") || isActiveLink("/releases")}
                  >
                    <Link
                      to={`/${intl.locale}/`}
                      onClick={() => {
                        onLeave()
                      }}
                    >
                      {" "}
                      {intl.formatMessage({ id: "nav.releases" })}
                    </Link>
                  </li>
                  <li className={isActiveLink("/artists")}>
                    <Link
                      to={`/${intl.locale}/artists`}
                      onClick={() => {
                        onLeave()
                      }}
                    >
                      {" "}
                      {intl.formatMessage({ id: "nav.artists" })}
                    </Link>
                  </li>
                  <li className={isActiveLink("/radio")}>
                    <Link
                      to={`/${intl.locale}/radio`}
                      onClick={() => {
                        onLeave()
                      }}
                    >
                      {" "}
                      {intl.formatMessage({ id: "nav.radio" })}
                    </Link>
                  </li>
                  <li className={isActiveLink("/education")}>
                    <Link
                      to={`/${intl.locale}/education`}
                      onClick={() => {
                        onLeave()
                      }}
                    >
                      {" "}
                      {intl.formatMessage({ id: "nav.education" })}
                    </Link>
                  </li>
                  <li className={isActiveLink("/about")}>
                    <Link
                      to={`/${intl.locale}/about`}
                      onClick={() => {
                        onLeave()
                      }}
                    >
                      {" "}
                      {intl.formatMessage({ id: "nav.about" })}
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="menu-connection justify-start align-center pl-8 pr-8 hidden md:flex">
                <div className="self-center flex flex-col w-full">
                  <div className="connection-title PPG-Bold">FOLLOW</div>
                  <div className="connection-links flex justify-between mt-2 ABC">
                    <a
                      target="_blank"
                      href={contactInfo.Bandcamp}
                      className="hem-external-link"
                    >
                      Bandcamp
                    </a>
                    <a
                      target="_blank"
                      href={contactInfo.Instagram}
                      className="hem-external-link"
                    >
                      Instagram
                    </a>
                    <a
                      target="_blank"
                      href={contactInfo.Twitter}
                      className="hem-external-link"
                    >
                      Twitter
                    </a>
                    <a
                      target="_blank"
                      href={contactInfo.Weibo}
                      className="hem-external-link"
                    >
                      Weibo
                    </a>
                    {/*
                    <a
                      target="_blank"
                      href={contactInfo.YouTube}
                      className="hem-external-link"
                    >
                      YouTube
                    </a>
                    <a
                      target="_blank"
                      href={contactInfo.Spotify}
                      className="hem-external-link"
                    >
                      Spotify
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <canvas ref={canvasRef} id="menu-rope"></canvas> */}
          </div>
        </animated.div>
      )
  )
}
