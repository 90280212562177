import "./src/styles/global.css"
import * as React from "react"
import AudioPlayer from "./src/components/audioPlayer"
import AudioPlayerWrapper from "./src/components/audioPlayerWrapper"
import Layout from './src/components/layout'
import './src/styles/fonts.scss'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const wrapPageElement = ({ element }) => {
  return (
    <AudioPlayerWrapper>
      <Layout>
        {element}
      </Layout>
      <AudioPlayer />
    </AudioPlayerWrapper>
  )
}
