// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-education-schedule-tsx": () => import("./../../../src/pages/education_schedule.tsx" /* webpackChunkName: "component---src-pages-education-schedule-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-radio-tsx": () => import("./../../../src/pages/radio.tsx" /* webpackChunkName: "component---src-pages-radio-tsx" */),
  "component---src-templates-artist-detail-tsx": () => import("./../../../src/templates/artist-detail.tsx" /* webpackChunkName: "component---src-templates-artist-detail-tsx" */),
  "component---src-templates-release-detail-tsx": () => import("./../../../src/templates/release-detail.tsx" /* webpackChunkName: "component---src-templates-release-detail-tsx" */)
}

