import React, { useContext } from "react"
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player/src"
import "react-h5-audio-player/src/styles.scss"
import { PlayerContext } from "./audioPlayerWrapper"
import * as R from "ramda"
import { usePrevious } from "react-use"
import { IArtist } from "../types/models"
import { useMobile } from "./useBreakPoint"
import { useIntl } from "gatsby-plugin-intl"

function toHHMM(secondsNumber: number) {
  let sec_num = Math.ceil(secondsNumber) // don't forget the second param
  let hours = Math.floor(sec_num / 3600)
  let minutes: string | number = Math.floor((sec_num - hours * 3600) / 60)
  let seconds: string | number = sec_num - hours * 3600 - minutes * 60

  if (minutes < 10) {
    minutes = minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return minutes + ":" + seconds
}

export default () => {
  const ref = React.useRef(null)
  const playerContext = useContext(PlayerContext)
  const prevPause = usePrevious(playerContext.pause)
  const [isMobile] = useMobile()
  if (playerContext.close) {
    return null
  }
  const intl = {
    menus: {
      en: {
        preview: "PREVIEW",
      },
      zh: {
        preview: "试听",
      },
      ja: {
        preview: "試聴する",
      },
    },
    formatMessage: ({ id }: any) => {
      const lang = localStorage.getItem("hem_lang") || 'zh'
      //@ts-ignore
      return intl.menus[lang || "en"][id]
    },
    locale: localStorage.getItem("hem_lang") || "en",
  }
  const currentSrc = R.pathOr("", [
    "playlist",
    playerContext.currentPlayIndex,
    "track",
    "url",
  ])(playerContext)

  const currentDuration = R.pipe(
    R.pathOr("", [
      "playlist",
      playerContext.currentPlayIndex,
      "track",
      "alternativeText",
    ]),
    (durationText: string) => {
      if (!!durationText && !!durationText?.match(/\{\{(.*)\}\}/)) {
        //@ts-ignore
        return Math.ceil(Number(durationText?.match(/\{\{(.*)\}\}/)[1]))
      }
    }
  )(playerContext)
  const trailPercent = `calc(${
    (30 / (currentDuration || 1)) * 100 || 0
  }% + 8px)`

  React.useEffect(() => {
    const progressEle = document.querySelector(".rhap_progress-filled")
    if (!!currentSrc && !!progressEle) {
      progressEle.style.width = trailPercent
    }
  }, [currentSrc, ref, trailPercent])

  const currentName = R.pathOr("", [
    "playlist",
    playerContext.currentPlayIndex,
    "name",
  ])(playerContext)

  const artistNames = R.pipe(
    R.pathOr([], ["playlist", playerContext.currentPlayIndex, "artists"]),
    R.map((artist: IArtist) => (
      <span className="artist-link">{artist.name}</span>
    )),
    R.intersperse(<span> / </span>)
  )(playerContext)

  React.useEffect(() => {
    if (ref.current) {
      if (playerContext.pause) {
        ref.current.audio.current.pause()
      } else if (prevPause) {
        ref.current.audio.current.play()
      }
    }
  }, [playerContext.pause])

  return React.useMemo(
    () => (
      <div
        id="hem-audio-player"
        className={playerContext.hidePlayer ? "hidden" : "block" + " ABC-Mono"}
      >
        <AudioPlayer
          ref={ref}
          autoPlayAfterSrcChange={true}
          showSkipControls={true}
          showJumpControls={false}
          src={currentSrc}
          onPause={() => playerContext.togglePause(true)}
          onPlay={e => {
            e.stopPropagation()
            if (e.target.currentTime < 30) {
              playerContext.togglePause(false)
            }
          }}
          onClickNext={() => playerContext.goToNext()}
          onClickPrevious={() => playerContext.goToPrev()}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          customProgressBarSection={[
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.CURRENT_TIME,
            <div className="rhap_time_divider">/</div>,
            <span className="rhap_time_duration">
              {toHHMM(currentDuration as number)}
            </span>,
          ]}
          layout="horizontal-reverse"
          header={
            <div className="flex flex-row-reverse justify-between items-center relative md:justify-start md:flex-row md:gap-6 text-base ABC">
              <span className="preview ABC-Mono">
                {intl.formatMessage({ id: "preview" })}
              </span>
              <div className="flex flex-col md:flex-row md:gap-6 info">
                <span>{currentName}</span>
                <span className="opacity-50">{artistNames}</span>
              </div>
              <button
                onClick={() => {
                  playerContext.setHidePlayer(true)
                  playerContext.togglePause(true)
                }}
                className="ABC-Mono hidden md:block absolute top-0 right-0"
              >
                CLOSE
              </button>
            </div>
          }
          customIcons={{
            previous: (
              <img
                style={{ width: 24, height: 24 }}
                src={require("../images/pre.svg").default}
                alt="previous"
              />
            ),
            play: (
              <img
                style={{ width: 25, height: 25 }}
                src={require("../images/play-contained.svg").default}
                alt="play"
              />
            ),
            pause: (
              <img
                style={{ width: 25, height: 25 }}
                src={require("../images/pause-contained.svg").default}
                alt="pause"
              />
            ),
            next: (
              <img
                style={{ width: 24, height: 24 }}
                src={require("../images/next.svg").default}
                alt="next"
              />
            ),
          }}
          defaultDuration={currentDuration}
          //@ts-ignore
          duration={currentDuration}
          onSeeking={e => {
            if (e.target.currentTime >= 30) {
              //@ts-ignore
              playerContext.togglePause(true)
            } else {
              playerContext.togglePause(false)
            }
          }}
        />
      </div>
    ),
    [currentSrc, playerContext.hidePlayer]
  )
}
