import * as React from "react"
import PropTypes from "prop-types"
import "./header.scss"
import * as R from "ramda"
import { useState } from "react"
import { Menu } from "./menu"
import { useSpring, config, useTransition, animated } from "react-spring"
import { easePolyInOut, easePolyOut } from "d3-ease"
import { useMobile } from "./useBreakPoint"
import { Link } from "gatsby"

const Header = () => {
  const [openMenu, toggleOpenMenu] = useState(false)
  const logoRef = React.useRef(null)
  const [isMobile] = useMobile()
  //@ts-ignore
  const isRadioPage = !!R.path<any>(["location", "pathname"])(window).match(
    /[en|ja|zh]{2}\/radio/
  )
  const reponsiveCloseWidth = isMobile ? 88 : 160
  const reponsiveOpenWidth = isMobile ? 88 : 464
  const reponsiveCloseHeight = isMobile ? 32 : 58
  const reponsiveOpenHeight = isMobile ? 32 : 168

  const styles = useSpring({
    to: {
      width: openMenu ? reponsiveOpenWidth : reponsiveCloseWidth,
      height: openMenu ? reponsiveOpenHeight : reponsiveCloseHeight,
      maxWidth: "calc(33% - 43px)",
    },
    config: {
      easing: easePolyInOut,
      duration: 200,
    },
  })

  const menuButtonTransitions = useTransition(openMenu, {
    from: { opacity: 1, transform: "translateX(0px)" },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: "translateX(-10px)" },
    config: {
      duration: 200,
      easing: easePolyOut,
    },
    onRest: () => {},
  })

  const closeButtonTransitions = useTransition(openMenu, {
    from: { opacity: 0, transform: "translateX(10px)" },
    enter: { opacity: 0.3, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: "translateX(10px)" },
    config: {
      duration: 200,
      easing: easePolyOut,
    },
    onRest: () => {},
  })
  return (
    <header
      className={`hem-header z-20 border-b border-black ${
        isRadioPage ? "header-dark-mode" : ""
      }`}
    >
      {/* TODO: needs refactor, href needs locale info */}
      <Link to={`/${localStorage.getItem("hem_lang") || "en"}/`}>
        <animated.img
          ref={logoRef}
          src={require("../images/logo.svg").default}
          className={`logo ${openMenu ? "open" : "close"} z-50`}
          style={styles}
        />{" "}
      </Link>
      {menuButtonTransitions(
        (styles, item) =>
          !item && (
            <animated.button
              className={`menu-button z-40 PPG-Bold `}
              style={styles}
              onClick={() => toggleOpenMenu(true)}
            >
              MENU
            </animated.button>
          )
      )}
      {closeButtonTransitions(
        (styles, item) =>
          item && (
            <animated.button
              className={`menu-button close z-40 PPG-Bold `}
              style={styles}
              onClick={() => toggleOpenMenu(false)}
            >
              CLOSE
            </animated.button>
          )
      )}
      <Menu
        logoRef={logoRef}
        open={openMenu}
        onClose={() => toggleOpenMenu(false)}
      />
    </header>
  )
}

export default Header
