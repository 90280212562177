import * as React from "react"
import { useState } from "react"
import * as R from "ramda"
import AudioPlayer from "./audioPlayer"
import { ISong } from "../types/models"

interface PlayerContextDefaultValue {
  playlist: ISong[]
  currentPlayIndex: number
  addToPlayList: (song: ISong) => void
  setPlayList: (playlist: ISong[], index?: number) => void
  pause?: boolean
  close?: boolean
  hidePlayer: boolean
  setHidePlayer: (hidePlayer: boolean) => void
  isCurrentSong: (song: ISong) => boolean
  togglePause: (flag?: boolean) => void
  goToNext: () => void
  goToPrev: () => void
}
// hem-production-hk.oss-cn-hongkong.aliyuncs.com
export const PlayerContext = React.createContext<PlayerContextDefaultValue>({
  playlist: [] as ISong[],
  currentPlayIndex: 0,
  addToPlayList: (song: ISong) => {},
  setPlayList: (playlist: ISong[], index?: number) => {},
  pause: false,
  close: false,
  hidePlayer: false,
  setHidePlayer: (hidePlayer: boolean) => {},
  isCurrentSong: (song: ISong) => false,
  togglePause: (flag?: boolean) => false,
  goToNext: () => {},
  goToPrev: () => {},
})

export default ({ children }: any) => {
  const [close, setClose] = useState(false)
  const [pause, setPause] = useState(false)
  const [playlist, setPlayList] = useState<ISong[]>([])
  const [currentPlayIndex, setCurrentPlayIndex] = useState(0)
  const [hidePlayer, setHidePlayer] = useState<boolean>(true)
  const addToPlayList = (song: ISong) => {
    setClose(false)
    if (R.includes(song)(playlist)) {
      const index = R.findIndex(R.propEq("id", song.id))(playlist)
      setCurrentPlayIndex(index)
    } else {
      setPlayList([...playlist, song])
      setCurrentPlayIndex([...playlist, song].length - 1)
    }
  }

  return (
    <PlayerContext.Provider
      value={{
        close,
        playlist,
        pause,
        currentPlayIndex,
        addToPlayList,
        hidePlayer,
        goToNext: () => {
          if (currentPlayIndex < playlist.length - 1) {
            setCurrentPlayIndex(currentPlayIndex + 1)
          }
        },
        goToPrev: () => {
          if (currentPlayIndex > 0) {
            setCurrentPlayIndex(currentPlayIndex - 1)
          }
        },
        setPlayList: (playlist: ISong[], index: number = 0) => {
          setPlayList(playlist)
          setCurrentPlayIndex(index)
        },
        setHidePlayer: (isHide: boolean) => setHidePlayer(isHide),
        isCurrentSong: song => {
          return (
            playlist[currentPlayIndex] &&
            song.id === playlist[currentPlayIndex].id
          )
        },
        togglePause: (flag?: boolean) => {
          if (flag !== undefined) {
            setPause(flag)
          } else {
            setPause(!pause)
          }
        },
      }}
    >
      {children}
    </PlayerContext.Provider>
  )
}
